import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, catchError, map, Observable, of, switchMap } from 'rxjs';

/**
 * Компонент требуется для безопасного получения картинок,
 * это достигается  через http запросы и последуещего прикрепления токена авторизации
 */
@Component({
  selector: 'app-secured-image',
  templateUrl: './secured-image.component.html',
  styleUrls: ['./secured-image.component.scss'],
})
export class SecuredImageComponent {
  private src$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() private set src(src: string) {
    this.src$.next(src);
  }

  public dataUrl$: Observable<SafeUrl> = this.src$.pipe(
    switchMap((url: string) => this.loadImage(url)),
    catchError(() => {
      return of();
    }),
  );

  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {}

  private loadImage(url: string): Observable<SafeUrl> {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .pipe(map((e) => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e as any))));
  }
}
