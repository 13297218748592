import { TablePageFilter } from '../table-page-filter-base';
import { IUsersFilterParams } from '@common/models/filters';
import { FilterAttributeData } from '@common/dto';

const DEFAULT_PARAMS: IUsersFilterParams = {
  defaultSortBy: 'name',
};

export class UsersFilterParams extends TablePageFilter<IUsersFilterParams> {
  id: number;
  login: string;
  name: string;
  fullName: string;
  isShowDeleted: boolean;
  roles: number[];
  permissions: string[];
  disciplineIds: number[];
  attributes: FilterAttributeData[];
  withAttributesOnly: boolean;
  withDisciplinesOnly: boolean;
  standardIds: number[];
  searchString: string;

  constructor(filter?: IUsersFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  public toObject(): IUsersFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      login: this.login,
      name: this.name,
      fullName: this.fullName,
      isShowDeleted: this.isShowDeleted,
      roles: this.roles,
      permissions: this.permissions,
      disciplineIds: this.disciplineIds,
      attributes: this.attributes,
      withAttributesOnly: this.withAttributesOnly,
      withDisciplinesOnly: this.withDisciplinesOnly,
      standardIds: this.standardIds,
      searchString: this.searchString,
    };
  }
}
