export enum HistoryType {
  uniqueParam = 1,
  funcionalType,
  funcionalTypeParam,
  family,
  familyVersion,
  familyVersionParam,
}

export enum HistoryActions {
  add = 1,
  edit,
  delete,
  archive,
  restore,
}
