<div class="standards">
  <div
    class="standards__item"
    *ngFor="let standard of _standards; trackBy: trackByIndex"
    [ngClass]="{ active: standard.isActive, switchable: switchable }"
    (click)="changeStandard(standard)"
  >
    <app-secured-image
      [pikTooltip]="standard.name"
      class="sidenav-standard__logo-image"
      src="{{ ApiPaths.Standards + '/' + standard.id + '/image' }}"
    ></app-secured-image>
  </div>
</div>
