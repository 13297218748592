export const ROUTES_PARTS = {
  PARAMETERS: 'parameters',
  UNIQUE_PARAMETERS: 'unique-parameters',
  COMPANIES: 'companies',
  FUNCTIONAL_TYPES: 'functional-types',
  FAMILIES: 'families',
  BINDINGS: 'bindings',
  BINDINGS_CHECK: 'bindings-check',
  SELECTION_OF_NOMENCLATURES: 'nomenclatures-selection',
  ROLES: 'roles',
  REQUESTS: 'requests',
  DISCIPLINES: 'disciplines',
  PROJECTS: 'projects',
  MATERIALS: 'materials',
  CATEGORIES: 'categories',
  SETTINGS: 'settings',
  TREE: 'tree',
  ATTRIBUTES: 'attributes',
  APPS: 'apps',
  USERS: 'users',
  DATA_TYPES: 'data-types',
  UNIT_TYPES: 'unit-types',
  FUNCTIONAL_TYPES_PARAMETERS: 'functional-types-parameters',
  MATERIALS_PARAMETERS: 'materials-parameters',
  LOAD_LOGS: 'loadlogs',
  STANDARDS: 'standards',
  DASHBOARD: 'dashboard',
  SPECIFICATIONS: 'specifications',
  NOMENCLATURE_TYPES: 'nomenclature-types',
  ADD_PAGE: 'add',
  NOT_FOUND: '404',
  READ_ONLY: 'read-only',
};

export const ROUTES = {
  tree: `${ROUTES_PARTS.TREE}`,
  attributes: `${ROUTES_PARTS.ATTRIBUTES}`,
  familiesList: `${ROUTES_PARTS.FAMILIES}`,
  family: (id: number) => `${ROUTES_PARTS.FAMILIES}/${id}`,
  requestsList: `${ROUTES_PARTS.REQUESTS}`,
  request: (id: number) => `${ROUTES_PARTS.REQUESTS}/${id}`,
  requestCreate: `${ROUTES_PARTS.REQUESTS}/create`,
  functionalTypesList: `${ROUTES_PARTS.FUNCTIONAL_TYPES}`,
  functionalType: (id: number) => `${ROUTES_PARTS.FUNCTIONAL_TYPES}/${id}`,
  functionalTypesParameters: `${ROUTES_PARTS.FUNCTIONAL_TYPES_PARAMETERS}`,
  binding: (id: number) => `${ROUTES_PARTS.BINDINGS}/${id}`,
  parameters: `${ROUTES_PARTS.PARAMETERS}`,
  bindingsCheck: `${ROUTES_PARTS.BINDINGS_CHECK}`,
  uniqueParameters: `${ROUTES_PARTS.UNIQUE_PARAMETERS}`,
  companies: `${ROUTES_PARTS.COMPANIES}`,
  bindingsList: `${ROUTES_PARTS.BINDINGS}`,
  specificationsList: `${ROUTES_PARTS.SPECIFICATIONS}`,
  nomenclatureTypesList: `${ROUTES_PARTS.NOMENCLATURE_TYPES}`,
  rolesList: `${ROUTES_PARTS.ROLES}`,
  disciplines: `${ROUTES_PARTS.DISCIPLINES}`,
  projectsList: `${ROUTES_PARTS.PROJECTS}`,
  materialsList: `${ROUTES_PARTS.MATERIALS}`,
  material: (id: number) => `${ROUTES_PARTS.MATERIALS}/${id}`,
  materialsParameters: `${ROUTES_PARTS.MATERIALS_PARAMETERS}`,
  categories: `${ROUTES_PARTS.CATEGORIES}`,
  settings: `${ROUTES_PARTS.SETTINGS}`,
  unitTypes: `${ROUTES_PARTS.UNIT_TYPES}`,
  dataTypes: `${ROUTES_PARTS.DATA_TYPES}`,
  loadLogs: `${ROUTES_PARTS.LOAD_LOGS}`,
  standards: `${ROUTES_PARTS.STANDARDS}`,
  dashboard: `${ROUTES_PARTS.DASHBOARD}`,
  usersList: `${ROUTES_PARTS.USERS}`,
  user: (id: number) => `${ROUTES_PARTS.USERS}/${id}`,
};
