import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TableActionTypeEnum } from './enums/table-action-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-action-button',
  templateUrl: './table-action-button.component.html',
  styleUrls: ['./table-action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableActionButtonComponent {
  get pending(): boolean {
    return this._pending;
  }

  @Input() set pending(value: boolean) {
    this._pending = value;
  }
  public tooltip;
  public icon;
  public _type: TableActionTypeEnum;

  @Output() action = new EventEmitter<TableActionTypeEnum>();
  private _pending: boolean;
  @Input() set type(value: TableActionTypeEnum) {
    this._type = value;
    switch (value) {
      case TableActionTypeEnum.Download:
        this.tooltip = this.translateService.instant('COMMON.DOWNLOAD');
        this.icon = 'download-bold.svg';
        break;
      case TableActionTypeEnum.Edit:
        this.tooltip = this.translateService.instant('COMMON.EDIT');
        this.icon = 'edit-2.svg';
        break;
      case TableActionTypeEnum.EditLicense:
        this.tooltip = this.translateService.instant('COMPANIES.EDIT_FORM_LICENSE_TOOLTIP');
        this.icon = 'edit.svg';
        break;
      case TableActionTypeEnum.Archive:
        this.tooltip = this.translateService.instant('COMMON.ARCHIVE');
        this.icon = 'archive.svg';
        break;
      case TableActionTypeEnum.Restore:
        this.tooltip = this.translateService.instant('COMMON.RESTORE');
        this.icon = 'restore.svg';
        break;
      case TableActionTypeEnum.Watch:
        this.tooltip = this.translateService.instant('COMMON.VIEW');
        this.icon = 'watch.svg';
        break;
      case TableActionTypeEnum.Delete:
        this.tooltip = this.translateService.instant('COMMON.REMOVE');
        this.icon = 'delete.svg';
        break;
    }
  }
  constructor(private translateService: TranslateService) {}
}
