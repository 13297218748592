import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPaths } from '@common/consts';
import { Observable } from 'rxjs';
import { SettingsItemDataDto } from '../types/settings.dto';

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService {
  constructor(private http: HttpClient) {}

  getallAppSettings(): Observable<SettingsItemDataDto[]> {
    return this.http.get<SettingsItemDataDto[]>(`${ApiPaths.ApplicationSettings}`);
  }

  updateSettingsItem(id, settingId, value): Observable<SettingsItemDataDto[]> {
    return this.http.put<SettingsItemDataDto[]>(`${ApiPaths.ApplicationSettings}/${id}`, { id, settingId, value });
  }

  addSettingsItem(settingId, value): Observable<SettingsItemDataDto[]> {
    return this.http.post<SettingsItemDataDto[]>(`${ApiPaths.ApplicationSettings}`, { settingId, value, id: 0 });
  }

  deleteSettingsItem(id): Observable<SettingsItemDataDto[]> {
    return this.http.delete<SettingsItemDataDto[]>(`${ApiPaths.ApplicationSettings}/${id}`);
  }

  getSettingsItemById(id): Observable<SettingsItemDataDto> {
    return this.http.get<SettingsItemDataDto>(`${ApiPaths.ApplicationSettings}/${id}`);
  }
}
