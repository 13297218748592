import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES_PARTS } from '@common/consts';
import {
  ImplicitRedirectComponent,
  Page404Component,
  ReadOnlyPageComponent,
  StartPageComponent,
} from '@core/components';
import { VisibilityOfSettingsGuardGuard } from '@features/settings/guards/visibility-of-settings-guard.guard';
import {
  ReadOnlyGuard,
  VisibilityOfBindingsGuard,
  VisibilityOfCatalogsGuard,
  VisibilityOfParametersGuard,
} from '@shared/guards';
import { SaasGuard } from '@shared/guards/saas.guard';

const appRoutes: Routes = [
  { path: 'implicit-redirect', component: ImplicitRedirectComponent, pathMatch: 'full' },
  {
    path: '',
    component: StartPageComponent,
    pathMatch: 'full',
    canActivate: [ReadOnlyGuard],
  },
  {
    path: ROUTES_PARTS.COMPANIES,
    loadChildren: () => import('./features/companies').then((m) => m.CompaniesModule),
    canActivate: [SaasGuard],
  },
  {
    path: ROUTES_PARTS.DASHBOARD,
    loadChildren: () => import('./features/dashboard').then((m) => m.DashboardModule),
    pathMatch: 'full',
    canActivate: [ReadOnlyGuard],
  },
  {
    path: ROUTES_PARTS.REQUESTS,
    loadChildren: () => import('./features/requests').then((m) => m.RequestsModule),
    canActivate: [ReadOnlyGuard],
  },
  {
    path: ROUTES_PARTS.FAMILIES,
    loadChildren: () => import('./features/families').then((m) => m.FamiliesModule),
    canActivate: [ReadOnlyGuard],
  },
  {
    path: ROUTES_PARTS.FUNCTIONAL_TYPES,
    loadChildren: () => import('./features/functional-types').then((m) => m.FunctionalTypesModule),
    canActivate: [ReadOnlyGuard],
  },
  {
    path: ROUTES_PARTS.BINDINGS,
    canActivate: [VisibilityOfBindingsGuard, ReadOnlyGuard],
    loadChildren: () => import('./features/bindings').then((m) => m.BindingsModule),
  },
  {
    path: ROUTES_PARTS.BINDINGS_CHECK,
    canActivate: [VisibilityOfBindingsGuard, ReadOnlyGuard],
    loadChildren: () => import('./features/bindings-check').then((m) => m.BindingsCheckModule),
  },
  {
    path: ROUTES_PARTS.SELECTION_OF_NOMENCLATURES,
    canActivate: [VisibilityOfBindingsGuard, ReadOnlyGuard],
    loadChildren: () => import('./features/elements-check').then((m) => m.ElementsCheckModule),
  },
  {
    path: ROUTES_PARTS.PARAMETERS,
    canActivate: [VisibilityOfParametersGuard, ReadOnlyGuard],
    loadChildren: () => import('./features/parameters').then((m) => m.ParametersModule),
  },
  {
    path: ROUTES_PARTS.UNIQUE_PARAMETERS,
    canActivate: [VisibilityOfParametersGuard, ReadOnlyGuard],
    loadChildren: () => import('./features/unique-parameters').then((m) => m.UniqueParametersModule),
  },
  {
    path: ROUTES_PARTS.FUNCTIONAL_TYPES_PARAMETERS,
    canActivate: [VisibilityOfParametersGuard, ReadOnlyGuard],
    loadChildren: () =>
      import('./features/functional-types/views/functional-types-parameters').then(
        (m) => m.FunctionalTypesParametersModule,
      ),
  },
  {
    path: ROUTES_PARTS.MATERIALS_PARAMETERS,
    canActivate: [VisibilityOfParametersGuard, ReadOnlyGuard],
    loadChildren: () =>
      import('@features/materials/views/materials-parameters').then((m) => m.MaterialsParametersModule),
  },
  {
    path: ROUTES_PARTS.DISCIPLINES,
    loadChildren: () => import('./features/disciplines').then((m) => m.DisciplinesModule),
    canActivate: [VisibilityOfCatalogsGuard, ReadOnlyGuard],
  },
  {
    path: ROUTES_PARTS.MATERIALS,
    canActivate: [ReadOnlyGuard],
    loadChildren: () => import('./features/materials').then((m) => m.MaterialsModule),
  },
  {
    path: ROUTES_PARTS.TREE,
    canActivate: [ReadOnlyGuard],
    loadChildren: () => import('./features/tree').then((m) => m.TreeModule),
  },
  {
    path: ROUTES_PARTS.ATTRIBUTES,
    loadChildren: () => import('./features/attributes').then((m) => m.AttributesModule),
    canActivate: [VisibilityOfCatalogsGuard, ReadOnlyGuard],
  },
  {
    path: ROUTES_PARTS.APPS,
    canActivate: [ReadOnlyGuard],
    loadChildren: () => import('./features/apps').then((m) => m.AppsModule),
  },
  {
    path: ROUTES_PARTS.USERS,
    canActivate: [ReadOnlyGuard],
    loadChildren: () => import('./features/users').then((m) => m.UsersModule),
  },
  {
    path: ROUTES_PARTS.ROLES,
    canActivate: [ReadOnlyGuard],
    loadChildren: () => import('./features/roles').then((m) => m.RolesModule),
  },
  {
    path: ROUTES_PARTS.PROJECTS,
    canActivate: [ReadOnlyGuard],
    loadChildren: () => import('./features/projects').then((m) => m.ProjectsModule),
  },
  {
    path: ROUTES_PARTS.CATEGORIES,
    loadChildren: () => import('./features/categories').then((m) => m.CategoriesModule),
    canActivate: [VisibilityOfCatalogsGuard, ReadOnlyGuard],
  },
  {
    path: ROUTES_PARTS.SETTINGS,
    loadChildren: () => import('./features/settings').then((m) => m.SettingsModule),
    canActivate: [VisibilityOfSettingsGuardGuard, ReadOnlyGuard],
  },
  /*  {
    path: ROUTES_PARTS.DATA_TYPES,
    canActivate: [VisibilityOfCatalogsGuard, ReadOnlyGuard],
    loadChildren: () => import('./features/data-types').then((m) => m.DataTypesModule),
  },*/
  {
    path: ROUTES_PARTS.UNIT_TYPES,
    canActivate: [VisibilityOfCatalogsGuard, ReadOnlyGuard],
    loadChildren: () => import('./features/unit-types').then((m) => m.UnitTypesModule),
  },
  {
    path: ROUTES_PARTS.LOAD_LOGS,
    canActivate: [ReadOnlyGuard],
    loadChildren: () => import('./features/load-logs').then((m) => m.LoadLogsModule),
  },
  {
    path: ROUTES_PARTS.STANDARDS,
    canActivate: [ReadOnlyGuard],
    loadChildren: () => import('./features/standards').then((m) => m.StandardsModule),
  },
  {
    path: ROUTES_PARTS.SPECIFICATIONS,
    canActivate: [VisibilityOfBindingsGuard, ReadOnlyGuard],
    loadChildren: () => import('./features/bindings-specifications').then((m) => m.SpecificationsModule),
  },
  {
    path: ROUTES_PARTS.NOMENCLATURE_TYPES,
    canActivate: [VisibilityOfBindingsGuard, ReadOnlyGuard],
    loadChildren: () => import('./features/bindings-nomenclature-types').then((m) => m.NomenclatureTypesModule),
  },
  {
    path: ROUTES_PARTS.ADD_PAGE,
    canActivate: [ReadOnlyGuard],
    loadChildren: () => import('./shared/modules/add-page/add-page.module').then((m) => m.AddPageModule),
  },
  { path: 'read-only', component: ReadOnlyPageComponent },
  { path: '**', component: Page404Component, canActivate: [ReadOnlyGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
