export const environment = {
  stand: 'development',
  lastBuildTime: '12-12-2024 17:13:07',
  version: '24.6.0',
  production: false,
  traceRoute: true,
  hrmImageEndpoint: 'https://hrm.bimteam.ru/api/v2',
  fmEndpoint: 'https://fm2-api.bimteam.ru/v1',
  locale: 'ru-RU',
  metrikaWebvisor: false,
  isGoogleIntegrationEnabled: true,
  isSaas: window['env']['isSaas'] === 'true' ? true : false,

  auth: {
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    redirectUri: window.location.origin + '/implicit-redirect',
    requireHttps: false,
    showDebugInformation: true,
    disableAtHashCheck: true,
    sendAccessToken: true,
    issuer: 'https://test.auth.pik-digital.ru',
    clientId: 'fm_spa',
    scope: 'fm_api allow_external',
    responseType: 'id_token token',
    allowedUrls: ['https://fm2-api.bimteam.ru'],
  },
};
