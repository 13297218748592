import { Injectable } from '@angular/core';
import { SettingsItemDataDto } from '../types/settings.dto';
import { BehaviorSubject, tap } from 'rxjs';
import { SettingsTabs } from '../types/types';
import { SettingsApiService } from './settings-api.service';
import { PermissionService } from '@core/account/services';
import { ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SettingsDataService {
  settings = new BehaviorSubject<SettingsItemDataDto[]>(null);
  settingsObject$ = new BehaviorSubject(null);
  settingsCommonTab = new BehaviorSubject<SettingsItemDataDto[]>(null);
  settingsDesktopTab = new BehaviorSubject<SettingsItemDataDto[]>(null);

  constructor(private settingsApiService: SettingsApiService, private route: ActivatedRoute) {}

  initData(): void {
    const settings = window.location.href.split('/').reverse()[0].includes('settings')
      ? null
      : JSON.parse(localStorage.getItem('app_settings'));

    if (settings?.expiresAt > new Date().getTime()) {
      this.setData(settings.data);
    } else {
      this.settingsApiService.getallAppSettings().subscribe((data) => {
        this.setData(data);
        localStorage.setItem(
          'app_settings',
          JSON.stringify({
            expiresAt: new Date().setDate(new Date().getDate() + 1),
            data,
          }),
        );
      });
    }
  }

  setData(data: SettingsItemDataDto[]): void {
    this.settings.next(data);
    const settingsObject = {};
    data.forEach(({ name, value }) => (settingsObject[name] = value));
    this.settingsObject$.next(settingsObject);
    this.settingsCommonTab.next(data.filter((item) => item.group === SettingsTabs.common));
    this.settingsDesktopTab.next(data.filter((item) => item.group === SettingsTabs.desktop));
  }
}
