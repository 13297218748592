import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import { BindingsCheckService } from '@features/bindings-check/services';
import { BindingsCheckTreeNode, BindingsCheckTreeNodeType } from '@features/bindings-check/utils';
import { debounceTime, distinctUntilChanged, share, takeUntil, tap } from 'rxjs/operators';
import { ThemeColors } from '@common/consts';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  expanded: boolean;
}

@Component({
  selector: 'app-bindings-check-tree',
  templateUrl: './bindings-check-tree.component.html',
  styleUrls: ['./bindings-check-tree.component.scss'],
})
export class BindingsCheckTreeComponent implements AfterViewInit, OnInit, OnDestroy {
  isAllExpanded$ = new BehaviorSubject(false);
  private _destroy$ = new Subject();
  public readonly BindingsCheckTreeNodeType = BindingsCheckTreeNodeType;
  public readonly ThemeColors = ThemeColors;
  transformer = (node: BindingsCheckTreeNode, level: number): FlatNode => ({
    ...node,
    level,
    expanded: node.type === BindingsCheckTreeNodeType.Nomenclature,
  });

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new NzTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(public bindingsCheckService: BindingsCheckService) {}

  ngOnInit() {
    this.subscribeToCheckResponse().subscribe();
    this.subscribeExpandCollapseTree().subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngAfterViewInit(): void {}

  subscribeToCheckResponse() {
    return this.bindingsCheckService.checkResponse$.pipe(tap((data) => this.dataSource.setData(data ?? [])));
  }

  subscribeExpandCollapseTree(): Observable<unknown> {
    return this.isAllExpanded$.pipe(
      takeUntil(this._destroy$),
      debounceTime(150),
      distinctUntilChanged(),
      tap((expandAll) => {
        expandAll ? this.treeControl.expandAll() : this.treeControl.collapseAll();
      }),
    );
  }
}
