<fieldset>
  <span class="mr2">{{ 'HISTORY_MODULE.FROM' | translate }}</span>
  <pik-datepicker [formControl]="dateFrom" inverse [max]="today"></pik-datepicker>
  <span class="ml2 mr2">{{ 'HISTORY_MODULE.TO' | translate }}</span>
  <pik-datepicker [formControl]="dateTo" inverse [max]="today"></pik-datepicker>
</fieldset>

<pik-loader class="loader" *ngIf="pending; else content"></pik-loader>

<ng-template #content>
  <ng-container *ngIf="historyData?.length; else noHistory">
    <div class="history-item" *ngFor="let item of historyData">
      <div>
        <span class="flex">
          <svg-icon class="calendar mr1" src="./assets/icons/calendar.svg"></svg-icon>
          <pik-tag class="mb1" color="#f2f2f2" textColor="#000">
            {{ item.changeDate | historyDate }}
          </pik-tag>
        </span>
        <div class="change__time-array" *ngFor="let changeTime of item.changeTimes">
          <pik-tag class="change__time-item" color="#f2f2f2" textColor="#000">
            {{ changeTime.time }}
          </pik-tag>
          <div>
            <div *ngFor="let value of changeTime.values">
              <ng-container [ngSwitch]="value.action">
                <ng-container *ngSwitchCase="HistoryActions.add">
                  <a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.ADD' | translate }}
                  <span class="entity_name" *ngIf="value.entity">{{ value.entity }}</span>
                  <span *ngIf="!Array.isArray(value.fieldName)" class="field_name">{{ value.fieldName }}</span>
                  <ng-container *ngIf="Array.isArray(value.fieldName)" class="field_name">
                    <span *ngFor="let item of value.fieldName; let last = last" class="field_name"
                      >{{ item }}<span *ngIf="!last">,</span></span
                    >
                  </ng-container>
                  <span *ngIf="value.newValue">
                    {{ 'HISTORY_MODULE.ACTIONS.WITH_VALUE' | translate }}
                    <app-parameters-view
                      *ngFor="let newVal of value.newValue"
                      [dataType]="value.newDataType"
                      [value]="newVal"
                    ></app-parameters-view>
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="HistoryActions.edit"
                  ><a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.EDIT' | translate }}
                  <span class="entity_name" *ngIf="value.entity">{{ value.entity }}</span>
                  <span class="field_name">{{ value.fieldName }}</span>
                  {{ 'HISTORY_MODULE.ACTIONS.FROM' | translate }}
                  <app-parameters-view
                    *ngFor="let oldVal of value.oldValue"
                    [dataType]="value.oldDataType"
                    [value]="oldVal || ('HISTORY_MODULE.ACTIONS.NOT_SPECIFIED' | translate)"
                  ></app-parameters-view>
                  {{ 'HISTORY_MODULE.ACTIONS.TO' | translate }}
                  <app-parameters-view
                    *ngFor="let newVal of value.newValue"
                    [dataType]="value.newDataType"
                    [value]="newVal || ('HISTORY_MODULE.ACTIONS.NOT_SPECIFIED' | translate)"
                  ></app-parameters-view>
                </ng-container>

                <ng-container *ngSwitchCase="HistoryActions.delete"
                  ><a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.DELETE' | translate }}
                  <span class="entity_name" *ngIf="value.entity">{{ value.entity }}</span>
                  <span *ngIf="!Array.isArray(value.fieldName)" class="field_name">{{ value.fieldName }}</span>
                  <ng-container *ngIf="Array.isArray(value.fieldName)" class="field_name">
                    <span *ngFor="let item of value.fieldName; let last = last" class="field_name"
                      >{{ item }}<span *ngIf="!last">,</span></span
                    >
                  </ng-container>
                  <span *ngIf="value.oldValue">
                    {{ 'HISTORY_MODULE.ACTIONS.WITH_VALUE' | translate }}
                    <app-parameters-view
                      *ngFor="let oldVal of value.newValue || value.oldValue"
                      [dataType]="value.oldDataType"
                      [value]="oldVal"
                    ></app-parameters-view>
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="HistoryActions.archive"
                  ><a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.ARCHIVE' | translate }}</ng-container
                >
                <ng-container *ngSwitchCase="HistoryActions.restore"
                  ><a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.RESTORE' | translate }}</ng-container
                >
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </ng-container>
</ng-template>

<ng-template #noHistory>
  <div class="mt2">{{ 'HISTORY_MODULE.NO_HISTORY' | translate }}</div>
</ng-template>
